import { Link } from 'gatsby';
import * as React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Logo } from '../icons/logo';

function SuccessPage(): JSX.Element {
  return (
    <>
      <SEO title="Success" />
      <Layout bgColour="pink" showNavAlt showFooterAlt>
        <Hero />
      </Layout>
    </>
  );
}

function Hero(): JSX.Element {
  return (
    <div className="px-4 py-12 lg:py-24 sm:px-6 lg:px-8">
      <Link to="/" className="block w-full max-w-sm p-4 mx-auto rounded-lg">
        <span className="sr-only">The Website Factory</span>
        <Logo aria-hidden variant="onPink" />
      </Link>
      <h1 className="mt-12 text-5xl font-black text-center text-cream font-display">
        Success! How easy was that? <br />
        Standby while we get the cogs turning.
      </h1>
    </div>
  );
}

export default SuccessPage;
